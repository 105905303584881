import store from "@/store";
import dayjs from "dayjs";
import { User } from "@/models";
import { pingHealthCheck } from "@/utils/healthCheck";
import i18n from "@/setup/i18n";
import RxDB from "@/rxdb/utils";
import { useRxdbCollection } from "@/composables/useRxdbCollection";

const initOfflineMode = async () => {
  const { execOnCollection: userCollection } = useRxdbCollection("user");
  const selector = { isCurrentUser: true };
  const user = await userCollection((c) => c.findOne({ selector }).exec());

  const weeks_validity =
    parseInt(process.env.VUE_APP_OFFLINE_WEEKS_VALIDITY) || 6;
  const endValidityDate = dayjs().subtract(weeks_validity, "week");
  const lastLoginDate = dayjs(user?.lastLoginDate);
  if (lastLoginDate > endValidityDate && user) {
    User.insert({ data: JSON.parse(JSON.stringify(user)) });
  } else {
    throw Error("Unauthorized access to offline mode");
  }
};

const isLoggedOrOffline = async (to, from, next) => {
  try {
    await RxDB.initializeRxDB();
  } catch (e) {
    console.error(
      "RxDB database initialization with its collections encountered an error",
      e
    );
    // TODO - MS - 09/12/24 - TO REMOVE AFTER MEP
    // This is a temporary solution to avoid the problem of the RxDB database
    // but that does not mean that there will be no problems to deal with
    // https://app.asana.com/0/1208273908108128/1208640200189311
    // const apiEnv = process.env.VUE_APP_SOCIO_GRPC_API_ENV;
    // if (apiEnv !== "local") {
    //   console.warn("Removing RxDB database and reinitializing...");
    //   await e.db?.remove();
    //   await RxDB.initializeRxDB();
    //   console.warn("Reinitialization of the RxDB database done");
    // }
  }

  store.commit("SET_APP_IS_LOADING", true);
  let isOnline = store.state.appIsOnline;
  try {
    await pingHealthCheck();
    store.commit("SET_APP_ONLINE", true);
  } catch (error) {
    console.error(error);
    store.commit("SET_APP_ONLINE", false);
    isOnline = false;
    store.dispatch(
      "notifications/showWarningNotification",
      i18n.t("offline.networkError")
    );
  }
  if (isOnline) {
    await store.dispatch("user/initializeCurrentUser");
  } else {
    try {
      await initOfflineMode();
    } catch {
      return next({ name: "unauthorized-access-offline-mode" });
    }
  }
  store.commit("SET_APP_IS_LOADING", false);
  next();
};

const isOffline = (from, to, next) => {
  if (store.state.appIsOnline) {
    next();
  } else {
    next("/");
  }
};

export { isLoggedOrOffline, isOffline };
